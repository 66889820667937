<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>

          <CCard class="p-2">
            <CCardBody>

              <BRow class="justify-content-center mb-3">
                <BCol md="12">
                  <div class="clearfix mb-5">
                    <!-- <h4 class="display-4"><strong>404</strong></h4> -->
                    <img src="/images/404-ERROR-PAGE.png" />
                    <h1 class="pt-3">Not Found</h1>
                    <h2 class="text-muted">페이지를 찾을 수 없습니다</h2>
                  </div>

                  <b-button size="lg" variant="info" :to="{path: '/' }">
                    <b-icon icon="arrow-down-left-square" class="mr-1"/>
                    메인 페이지로 이동</b-button>
                </BCol>
              </BRow>

            </CCardBody>
          </CCard>

      </CCol>
    </CRow>
  </CContainer>


</template>




<script>
export default {
    name: 'NotFound'
}
</script>
